<template>
  <div>
    <!-- Show the text and filters -->
    <v-row>
      <v-col cols="12" sm="6">
        <div class="overline mb-2">
          Credit Usage
        </div>
      </v-col>

      <v-spacer />

      <v-col cols="12" sm="6" lg="4">
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="showCredits"
              label="Show Credits"
              color="primary"
              dense
            />
          </v-col>

          <v-col cols="6">
            <v-checkbox
              v-model="showDebits"
              label="Show Debits"
              color="primary"
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Render the chart component -->
    <apex-chart
      height="300"
      :options="chartOptions"
      :type="chartType"
      :series="series"
    />
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration object
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Import children components
const DateRangeSelector = () => import(/* webpackChunkName: "date-range-selector" */ "@/blocks/common/form/DateRangeSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "UsageChart",

  // Register the components
  components: {
    ApexChart
  },

  // Accept incoming data from parent
  props: {
    data: Object,
  },

  // Define local data variables
  data: () => ({
    showCredits: true,
    showDebits: true,
  }),

  // Define computable properties
  computed: {
    /**
     * Get the date range
     *
     * @returns {array}
     */
    dateRange() {
      // Get all the date values
      const dates = []

      // Loop through the data
      for (const type of Object.keys(this.data)) {
        // If the type is not allowed
        if (type === "credits" && !this.showCredits) continue
        if (type === "debits" && !this.showDebits) continue

        // Get all the values
        const values = Object.values(this.data[type]).flat()

        // Loop through values
        for (const value of values) {
          // Push the date
          dates.push(dayjs(value.date).unix() * 1000)
        }
      }

      // Sort the dates
      dates.sort()

      // Return the date range
      return [dates[0], dates[dates.length - 1]]
    },

    /**
     * Get the series data
     *
     * @returns {array}
     */
    series() {
      // The color map
      const colorMap = {
        "influencer-discovery": "#ff7676",
        "influencer-insight": "#74b9ff",
        "audience-overlap": "#55efc4",
        "unified-balance": "#ffeaa7",

        "social-sentiment": "#a29bfe",
        "competitor-check": "#00b894",
        "content-discovery": "#fd79a8",
        "influencer-group": "#00b894",
        "campaign-tracking": "#a29bfe",
        "mention-tracking": "#fd79a8",
        "influencer-outreach": "#00b894",
      }

      // The array to return
      const toReturn = []

      // Loop through the data
      for (const type of Object.keys(this.data)) {
        // If the type is not allowed
        if (type === "credits" && !this.showCredits) continue
        if (type === "debits" && !this.showDebits) continue

        // Loop through all the keys
        for (const key of Object.keys(this.data[type])) {
          // If the values are not empty
          if (this.data[type][key].length > 0) {
            // Set 0 as the default value for the date range
            const items = []

            // Loop through each date between the range
            for (let date = dayjs(this.dateRange[0]); date <= dayjs(this.dateRange[1]); date = dayjs(date).add(1, "day")) {
              // Get the string value of the date
              const string = date.format("YYYY-MM-DD")

              // If found the object
              const found = this.data[type][key].find((item) => item.date === string)

              // Push the value
              items.push({
                x: date.unix() * 1000,
                y: found ? Number(found.amount) / constants.conversionRate : 0,
              })
            }

            // Push the values
            toReturn.push({
              color: type === "credits" ? "#00b894" : colorMap[key] || "#ff7676",
              name: capitalizeString(key.replace("-", " ")),
              data: items,
            })
          }
        }
      }

      // Return the values
      return toReturn
    },

    /**
     * Get the chart type
     *
     * @returns {string}
     */
    chartType() {
      // If there are more than one data points
      const count = this.series.reduce((acc, item) => acc + item.data.length, 0)

      // If there are more than 1 data points
      return count > 1 ? "line" : "bar"
    },

    /**
     * Get the chart configurations object
     *
     * @returns {object}
     */
     chartOptions() {
      return merge(apexChartBaseOptions(), {
        chart: {
          offsetX: 0,
        },
        xaxis: {
          type: "datetime",
          min: this.dateRange[0],
          max: this.dateRange[1],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          labels: {
            formatter: (seriesName) =>
              nFormatter(seriesName).toLocaleUpperCase(),
          },
          type: "numeric",
          title: {
            text: "Credits",
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 5,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== "undefined") {
                return `${nFormatter(y.toFixed(2))}`;
              }
              return y;
            },
          },
        },
      })
    }
  }
}
</script>
